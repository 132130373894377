import React from "react"
import {Layout} from "../components/Layout"
import {
  CountsSection,
  DemoButton,
  Faq,
  GetStartedButton,
  LearnMoreButton,
  ServiceSection,
  AutoScrollBanner,
  FeatureSection,
  FeatureSectionRight,
  VimeoPlayer,
} from "../commonComponents"
import {Helmet} from "react-helmet"
import _ from 'lodash'
import {BiLoaderAlt} from "react-icons/bi";

const FRONTEND_API_SERVER_V2_ENDPOINT = "https://frontend-api-server-v2.api.irisagent.com";

export default function SupportOperations() {
  const HeroVideo = () => {
    return (
      <div className="video-player-wrapper" >
        <VimeoPlayer url="https://vimeo.com/1064494967" autoplay="true" loop="true" />
      </div>
    )
  }

  const [tagInputText, setTagInputText] = React.useState<string>();
  const [tags, setTags] = React.useState<string[]>();
  const [isLoading, setLoading] = React.useState(false);
  const [isIdle, setIdle] = React.useState(true);

  React.useEffect(() => {
    if ( _.isEmpty(tagInputText) ) {
      setIdle(true);
    }
  }, [tagInputText])

  async function onComputeTags() {
    if ( _.isEmpty(tagInputText) ) {
        return;
    }

    try {
      setLoading(true);
      setTags([]);
      const response = await fetch(`${FRONTEND_API_SERVER_V2_ENDPOINT}/v1/community/text/tags?text=${tagInputText}`, {
        method: "GET",
      });
      const data = await response.json();
      setTags(data.tags);
    } catch (e) {
      console.error(`Error while fetching tags: ${e}`);
    } finally {
      setLoading(false);
      setIdle(false);
    }
  }

  return (
    <Layout
      keywords="ticket automation, automated ticket tagging, proactive customer support, Zendesk ticket tagging, Salesforce ticket tagging"
      title="Automated Ticket System: Ticket Tagging & Routing Made Easy"
      description="We offer seamless ticket automation, routing, and tagging. Simplify your support process and prioritize tasks effectively. Book your demo now!"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/support-operations/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span className="text-highlight">Automated Ticket Tagging</span>
              &nbsp; for Accurate Reporting
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
              Automated tagging, routing and prioritization <br/>
              of tickets. Get rid of manual reporting. Free up <br/>
              your agents from mundane tagging.
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              Automated tagging, routing and prioritization <br/>
              of tickets. Get rid of manual reporting. Free up <br/>
              your agents from mundane tagging.
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center mt-4 mt-lg-0 col-xl-0" style={{ padding: 0 }}>
            <div className="tags-widget mx-auto">
              <div className="tags-header">
                <div class="tagging-header font-weight-bold">
                  Try automatic tagging now
                </div>
                <br/>

              </div>
              <div className="tags-input">
                <textarea className={"tag-input"} type="text" id="textInput" placeholder="Enter a support query..."
                          onChange={e => setTagInputText(e.target.value)}/>
                <p className={"tag-input-span"}>Try: how to return my order?</p>
                {
                  isLoading
                      ? <div style={{animation: "spin 2s linear infinite", fontWeight: "20px", width: "10px"}}
                             className={"my-0 mx-auto"}>
                        <BiLoaderAlt
                            className="modal__spinner-style"
                            fadeIn="none"
                        />
                      </div>
                      : <button className={"intro-integration-button w-100"} onClick={onComputeTags}>GET AI TAG</button>
                }
              </div>
              {
                  !isLoading && !isIdle &&
                  <div className="compute-tags-output font-weight-bold" id="outputText">{_.isEmpty(tags) ? "No tags found" : tags[0].tag}</div>
              }
            </div>
          </div>
        </div>
      </section>

      <AutoScrollBanner />
      {/* <!-- ======= About Auto-detected incidents Section ======= --> */}
      <div className={"col-lg-12 col-md-12 col-sm-24 col-xs-24"} style={{paddingLeft: "12%", paddingRight: "12%"}}>
                    <HeroVideo />
                </div>
      <br/><br/>
     <FeatureSection
            title="Tag and route tickets automatically"
            description="IrisAgent discovers tags customized to your domain, automatically tags new tickets, and routes the ticket to the right person. Get rid of manual and inaccurate tagging for good."
            imageUrl="/img/tagging.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <FeatureSectionRight
            title="Predict and prevent escalations proactively"
            description="Discover at-risk tickets with automated revenue and customer sentiment impact analysis for every ticket and bug. Act on urgent tickets with real-time severity, customer health and sentiment monitoring and alerts."
            imageUrl="/img/sentiment.webp"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <FeatureSection
            title="Early warning engine to discover customer issues proactively"
            description="Get real-time alerts about trending product and customer experience issues detected from anomalies in support and product data."
            imageUrl="/img/proactiveSupport3.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does ticket automation work?"
                  answer="Ticket automation works by utilizing an automated ticket system to streamline the process of receiving, categorizing, and routing tickets. The automated ticketing system takes incoming tickets, routes them to the appropriate team or individual based on predefined rules, and tags them based on their content. This helps to prioritize and manage tickets more efficiently, resulting in faster resolution times."
                />
                <Faq
                  id="faq2"
                  question="Can you explain automated ticket routing?"
                  answer="Automated ticket routing involves automatically sorting and distributing tickets based on certain criteria. These may include factors such as the nature of the issue, the team or individual with the relevant expertise, and the severity of the issue. Automated ticket routing ensures that the right person handles the ticket, thus improving the efficiency and effectiveness of the ticket resolution process."
                />
                <Faq
                  id="faq3"
                  question="What are the benefits of ticket automation?"
                  answer="The benefits of ticket automation are manifold. Firstly, it helps in efficient management and prompt resolution of customer issues, thus enhancing customer satisfaction. Secondly, it reduces the workload of the customer support team, allowing them to focus on complex issues that require expert attention. It also minimizes the possibility of errors due to manual intervention and maintains a record of all tickets, which can be useful for future reference or analysis."
                />
                <Faq
                  id="faq4"
                  question="What types of tickets can be automated?"
                  answer="An automated ticketing system can manage different types of tickets. These could range from general queries to technical issues or complaints. What's essential is appropriately setting up the system to recognize and handle these varied ticket types. Finally, by consistently tagging and routing tickets, a system can help identify trends. This could involve repeated issues or frequent queries, allowing the business to improve its services or products accordingly."
                />
                <Faq
                  id="faq5"
                  question="How can ticket automation help in tracking trends?"
                  answer="Ticket automation can significantly aid in tracking trends within customer queries and issues. The automated ticket system simplifies data collection by efficiently categorizing tickets through automated ticket tagging. This allows us to gather insight into recurring issues, peak times for customer inquiries, and frequently asked queries. Businesses can proactively address common problems and improve customer service by monitoring these trends."
                />
                <Faq
                  id="faq6"
                  question="What are the best practices for ticket automation?"
                  answer="Ticket automation is an efficient way to manage and streamline customer service. Some best practices include creating an automated ticket triage system to prioritize issues based on severity, using AI to respond to simple issues, conditional automation for specific situations, and maintaining a human touch in responses to ensure customer satisfaction. Regular analyses for continuous improvement also play a crucial role in a successful automation system."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}

      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
